/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';

function ClassicSixFAQ({ noHeader, lang }) {
  const [questions, setQuestions] = useState([]);
  const toggleAnswer = (id, e) => {
    e.preventDefault();
    const cloneQuestions = [...questions];
    cloneQuestions.forEach((faq) => {
      if (faq.id === id) {
        faq.isOpen = !faq.isOpen;
      }
    });
    setQuestions(cloneQuestions);
  };

  const faq = {
    en: [
      {
        id: 1,
        title: 'What is the Classic Six Ambassador Circle? ',
        answer: 'The Classic Six Ambassador Circle is our free loyalty program. It’s a way to reward our most dedicated customers every time they shop and share! We provide exclusive benefits through our three distinguished tiers: Timeless, Elegant, and Heritage. The more points you earn, the more rewards you receive!',
      },
      {
        id: 2,
        title: 'How do I join?',
        answer: 'Simple and easy. Enter your email address where it says "JOIN." You will get an email from us to verify your account and then you are ready to go!  The best news? No password is ever required. Every time you login with your email address in the top right corner, we send a magic link to your email that will take you right to your account! ',
      },
      {
        id: 3,
        title: 'How is my membership status determined?',
        answer: 'Your Ambassador Circle tier is determined by how many points you earn throughout the calendar year. There are lots of ways to earn. Not just by spending!',
      },
      {
        id: 4,
        title: 'How do I earn?',
        answer: 'You can earn points in lots of ways. The easiest is by shopping, of course.<br /><br />$1 dollar spent earns 1 point. You can make these purchases in-store or online as long as the email address you used to join is the one you are using to check out!<br /><br />The amount of dollars spent will automatically update even if you purchased last year, because all purchases from 2024 will be considered!',
      },
      {
        id: 5,
        title: 'What are other ways to earn?',
        answer: 'Great question. It is an Ambassador Circle after all. We want you to earn points for being our Ambassador! Every time you share your personalized link with your friends and family or refer a friend to join the Circle, you earn! More ways to earn are coming soon, like submitting a review, signing up for our mailing list, and tagging and sharing us on Instagram! Stay tuned for more on that.',
      },
      {
        id: 6,
        title: 'Where can I track progress?',
        answer: 'You can track your progress by logging into your Ambassador Circle account and going to your benefits page. When you level up to a new tier, you will see all the new perks on your benefits page!',
      },
      {
        id: 7,
        title: 'Do Tiers Expire or Reset?',
        answer: 'Yes. Tier resets are based on your past year\'s spend. Every January, your points reset to 0, and you\'ll need to re-qualify for your current level in order to remain there after the next level reset.',
      },
      {
        id: 8,
        title: 'What is early sale access?',
        answer: 'As a Classic Six Ambassador all tiers get to shop our biggest sales a full day ahead of everyone else, so you get the best choice in limited stock items and exclusive sale and launch items. How\'s that for EARLY access?',
      },
      {
        id: 9,
        title: 'What do I actually get on my birthday?',
        answer: 'At each level, you\'ll be able to add your birthday at any time and receive 100 birthday points on your actual day! Happy birthday to YOU!',
      },
      {
        id: 10,
        title: 'What are Private Sale Events?',
        answer: 'Throughout the year, you\'ll be invited to shop ambassador-exclusive private sales featuring special events in our store, seasonal must-haves, warehouse sales and more. Yes, you heard that right. A warehouse sale. ;)',
      },
      {
        id: 11,
        title: 'Leveling up to the Next Tier: How it works',
        answer: 'You\'ll graduate to the next level once you\'ve reached the max amount of points in your current one. There are so many ways to earn points, not just spending. Sharing on social or with your friends can also earn you points!',
      },
      {
        id: 12,
        title: 'How Do I Exit the Classic Six ambassador circle?',
        answer: 'The option to deactivate your Classic Six Ambassador account requires the below:<br /><br />Please email us at <a href="mailto:customerservice@classicsixny.com">customerservice@classicsixny.com</a> to request to have your account deleted.',
      },
      {
        id: 13,
        title: 'I am having issues logging into my account, What should I do?',
        answer: 'If you are having issues logging into your account, please first make sure you are logging into your Classic Six Ambassador Circle account at <a href="https://classicsix.heyethos.com" target="_blank">https://classicsix.heyethos.com</a>, not your main Classic Six Account. Once there, navigate to the “Login” button on the top right corner of the page and add your email. We will then send a magic link to your email to login. No passwords are required! Please also check all SPAM folders in case your email client is filtering emails from us.<br /><br />If you are still having difficulty logging in please contact <a href="mailto:customersevice@classicsixny.com">customersevice@classicsixny.com</a> for further assistance!',
      },
    ],
  };

  useEffect(() => {
    setQuestions(faq.en);
  }, []);

  return (
    <div className="asset-faq">
      {!noHeader
      && <h2 className="digital-asset__subheader -center">FAQ</h2>}
      {questions.map((faq) => (
        <div
          key={faq.id}
          className="asset-faq__item">
          {faq.isOpen
            ? (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                -
              </a>
            )
            : (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                +
              </a>
            )}
          <div
            className="asset-faq__item--question"
            onClick={(e) => toggleAnswer(faq.id, e)}>
            {faq.title}
          </div>
          <div
            className={faq.isOpen ? 'asset-faq__item--answer -open' : 'asset-faq__item--answer'}
            dangerouslySetInnerHTML={{ __html: faq.answer }}>
          </div>
        </div>
      ))}
      
    </div>
  );
}

export default ClassicSixFAQ;
